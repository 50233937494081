import {hasObjectKey} from "@/util/common";

export const ROLE_USER = "ROLE_USER";
export const ROLE_CUSTOMER = "ROLE_CUSTOMER";
export const ROLE_BUSINESS_MEMBER = "ROLE_BUSINESS_MEMBER";
export const ROLE_BUSINESS_ADMIN = "ROLE_BUSINESS_ADMIN";
export const ROLE_BUSINESS_SUPER_ADMIN = "ROLE_BUSINESS_SUPER_ADMIN";
export const ROLE_CORPORATE = "ROLE_CORPORATE";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_CONFIG_READER = "ROLE_CONFIG_READER";
export const ROLE_CONFIG_MANAGER = "ROLE_CONFIG_MANAGER";
export const ROLE_USER_READER = "ROLE_USER_READER";
export const ROLE_USER_MANAGER = "ROLE_USER_MANAGER";
export const ROLE_REFUND_READER = "ROLE_REFUND_READER";
export const ROLE_REFUND_MANAGER = "ROLE_REFUND_MANAGER";
export const ROLE_NOTIFICATION_READER = "ROLE_NOTIFICATION_READER";
export const ROLE_NOTIFICATION_MANAGER = "ROLE_NOTIFICATION_MANAGER";
export const ROLE_POST_READER = "ROLE_POST_READER";
export const ROLE_POST_MANAGER = "ROLE_POST_MANAGER";
export const ROLE_PROMO_READER = "ROLE_PROMO_READER";
export const ROLE_PROMO_MANAGER = "ROLE_PROMO_MANAGER";
export const ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN";
export const ROLE_MASTER = "ROLE_MASTER";

export const roleHierarchy = {
    ROLE_ADMIN: [ROLE_USER],
    ROLE_CONFIG_READER: [ROLE_ADMIN],
    ROLE_CONFIG_MANAGER: [ROLE_CONFIG_READER],
    ROLE_USER_READER: [ROLE_ADMIN],
    ROLE_USER_MANAGER: [ROLE_USER_READER],
    ROLE_REFUND_READER: [ROLE_ADMIN],
    ROLE_REFUND_MANAGER: [ROLE_REFUND_READER],
    ROLE_NOTIFICATION_READER: [ROLE_ADMIN],
    ROLE_NOTIFICATION_MANAGER: [ROLE_NOTIFICATION_READER],
    ROLE_POST_READER: [ROLE_ADMIN],
    ROLE_POST_MANAGER: [ROLE_POST_READER],
    ROLE_PROMO_READER: [ROLE_ADMIN],
    ROLE_PROMO_MANAGER: [ROLE_PROMO_READER],
    ROLE_SUPER_ADMIN: [ROLE_CONFIG_MANAGER, ROLE_USER_MANAGER, ROLE_REFUND_MANAGER, ROLE_NOTIFICATION_MANAGER, ROLE_POST_MANAGER, ROLE_PROMO_MANAGER],
    ROLE_MASTER: [ROLE_SUPER_ADMIN]
};

export const ADMIN_ROLES_INFO = {
    ROLE_ADMIN: {
        label: "Admin",
        groupKey: "admin",
        groupLabel: "Admin",
        description: "Peran dasar dengan tanggung jawab manajemen sistem umum.",
        selectable: false
    },
    ROLE_CONFIG_READER: {
        label: "Config Reader",
        groupKey: 'configuration',
        groupLabel: 'Konfigurasi',
        description: "Peran dengan izin dapat membaca konfigurasi sistem/aplikasi.",
        selectable: true
    },
    ROLE_CONFIG_MANAGER: {
        label: "Config Manager",
        groupKey: 'configuration',
        groupLabel: 'Konfigurasi',
        description: "Peran dengan izin dapat memodifikasi konfigurasi sistem/aplikasi.",
        selectable: true
    },
    ROLE_USER_READER: {
        label: "User Reader",
        groupKey: 'user',
        groupLabel: 'User',
        description: "Peran dengan izin dapat membaca data akun pengguna.",
        selectable: true
    },
    ROLE_USER_MANAGER: {
        label: "User Manager",
        groupKey: 'user',
        groupLabel: 'User',
        description: "Peran dengan izin dapat memodifikasi akun pengguna.",
        selectable: true
    },
    ROLE_REFUND_READER: {
        label: "Refund Reader",
        groupKey: 'refund',
        groupLabel: 'Refund',
        description: "Peran dengan izin dapat membaca data refund.",
        selectable: true
    },
    ROLE_REFUND_MANAGER: {
        label: "Refund Manager",
        groupKey: 'refund',
        groupLabel: 'Refund',
        description: "Peran dengan izin dapat memodifikasi data refund.",
        selectable: true
    },
    ROLE_NOTIFICATION_READER: {
        label: "Notification Reader",
        groupKey: 'notification',
        groupLabel: 'Notifikasi',
        description: "Peran dengan izin dapat membaca data notifikasi.",
        selectable: true
    },
    ROLE_NOTIFICATION_MANAGER: {
        label: "Notification Manager",
        groupKey: 'notification',
        groupLabel: 'Notifikasi',
        description: "Peran dengan izin dapat memodifikasi data notifikasi.",
        selectable: true
    },
    ROLE_POST_READER: {
        label: "Post Reader",
        groupKey: 'post',
        groupLabel: 'Post/Artikel',
        description: "Peran dengan izin dapat membaca data post.",
        selectable: true
    },
    ROLE_POST_MANAGER: {
        label: "Post Manager",
        groupKey: 'post',
        groupLabel: 'Post/Artikel',
        description: "Peran dengan izin dapat memodifikasi data post.",
        selectable: true
    },
    ROLE_PROMO_READER: {
        label: "Promo Reader",
        groupKey: 'promo',
        groupLabel: 'Promo',
        description: "Peran dengan izin dapat membaca data promo.",
        selectable: true
    },
    ROLE_PROMO_MANAGER: {
        label: "Promo Manager",
        groupKey: 'promo',
        groupLabel: 'Promo',
        description: "Peran dengan izin dapat memodifikasi data promo.",
        selectable: true
    },
    ROLE_SUPER_ADMIN: {
        label: "Super Admin",
        groupKey: "super_admin",
        groupLabel: "Super Admin",
        description: "Peran tertinggi dengan izin akses penuh.",
        selectable: true
    },
    ROLE_MASTER: {
        label: "Master",
        groupKey: "master",
        groupLabel: "Master",
        description: "Peran untuk pengembang sistem/aplikasi.",
        selectable: false
    }
}

export const parseRole = function (role) {
    if (hasObjectKey(roleHierarchy, role)) {
        return roleHierarchy[role]
    }

    return [];
};

export const isEqualRole = (role, requiredRole) => {
    if (role === requiredRole) {
        return true
    }

    const deepRoles = parseRole(role)
    for (let x=0; x<deepRoles.length; x++) {
        const inDeep = isEqualRole(deepRoles[x], requiredRole)
        if (inDeep) {
            return true
        }
    }

    return false
};

export const inRoles = (hasRoles, requiredRole) => {
    for (let x=0; x<hasRoles.length; x++) {
        const isEqual = isEqualRole(hasRoles[x], requiredRole)
        if (isEqual) {
            return true
        }
    }

    return false
};

export function isRoleGranted(hasRoles, requiredRoles) {
    for (let x=0; x<requiredRoles.length; x++) {
        const exists = inRoles(hasRoles, requiredRoles[x])
        if (exists) {
            return true
        }
    }

    return false
}

export function findAllDescendantsOfRole(role) {
    if (!roleHierarchy[role] || roleHierarchy[role].length === 0) {
        return []
    }

    let descendants = []
    for (const childRole of roleHierarchy[role]) {
        descendants.push(childRole)
        const childDescendants = findAllDescendantsOfRole(childRole)
        descendants = [...descendants, ...childDescendants]
    }

    return [...new Set(descendants)]
}