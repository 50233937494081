import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import routerGuard from "@/plugins/routerGuard";
import appHead from "@/plugins/appHead";
import fontAwesome from "@/plugins/fontAwesome";
import httpClient from "@/plugins/httpClient";
import smsPayAssets from "@/plugins/smsPayAssets";

import "@/assets/scss/app.scss"
import "bootstrap/dist/js/bootstrap.min.js"
import moment from "moment";
moment.locale("id");

createApp(App)
    .use(store)
    .use(router)
    .use(routerGuard)
    .use(appHead)
    .use(fontAwesome)
    .use(httpClient, { baseUrl: 'https://api.smspay.co.id' })
    .use(smsPayAssets, { baseUrl: 'https://assets.smspay.co.id' })
    .mount('#app')
