<template>
    <div v-if="message" :class="rootClasses">
        <div class="flex-fill">
            <fa-icon v-if="icon" :icon="icon" :pulse="isSpinnerIcon" :class="iconClasses"/> {{ message }}
        </div>
        <div v-if="closeable" :class="closeableClasses">
            <a href="#" @click.prevent="$emit('close')" class="text-decoration-none text-danger">
                <fa-icon icon="times"/>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppMessage",
    emits: ['close'],
    props: {
        message: String,
        type: {
            type: String,
            default: 'dark',
            validator(value) {
                return ['success', 'warning', 'danger', 'info', 'dark', 'primary', 'secondary'].includes(value)
            }
        },
        colorTo: {
            type: String,
            default: 'text',
            validator(value) {
                return ['text', 'background'].includes(value)
            }
        },
        icon: {
            type: String,
            default: 'info-circle'
        },
        closeable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isSpinnerIcon() {
            return this.icon === "spinner"
        },
        rootClasses() {
            const classes = ['d-flex']

            if (this.type) {
                switch (this.colorTo) {
                    case 'text':
                        classes.push('text-' + this.type)
                        break;
                    case 'background':
                        classes.push('p-2 bg-opacity-10 rounded border border-opacity-25')
                        classes.push('border-' + this.type)
                        classes.push('bg-' + this.type)
                        break;
                }
            }

            return classes.join(' ')
        },
        closeableClasses() {
            const classes = []
            if (this.type) {
                if (this.colorTo === 'background') {
                    classes.push('ms-2 ps-2 border-start border-opacity-25 border-' + this.type)
                } else if (this.colorTo === 'text') {
                    classes.push('ms-2')
                }
            }

            return classes.join(' ')
        },
        iconClasses() {
            const classes = []
            if (this.colorTo === 'background') classes.push('text-' + this.type)
            return classes.join(' ')
        }
    }
}
</script>