import { createStore } from 'vuex'
import dashboard from "@/store/dashboard";
import user from "@/store/user";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    dashboard
  }
})
