const redirectTo = function (name) {
    if (typeof name === "string" && name.trim() !== "") {
        return {name:name}
    }

    return {path:'/'}
};

export default {
    install(app, options) {
        const $router = app.config.globalProperties.$router
        const $store = app.config.globalProperties.$store

        if (!$router || !$store) {
            throw new Error("Router guard is require router and store. Please install it after they are already installed.")
        }

        $router.beforeEach(to=> {
            const requireAuth = to.matched.some(route=>{
                const metaGuard = route.meta?.guard
                return typeof metaGuard === "boolean" ? metaGuard : false
            })

            if (requireAuth) {
                const isAuthorized = $store.getters['user/isAuthorized']
                if (!isAuthorized) {
                    return redirectTo(options?.loginName ? options.loginName : 'Home')
                }
            }
        })
    }
}