export function hasObjectKey(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key)
}

export function callbackCaller(callable, ...args) {
    if (typeof callable === "function") {
        return callable.apply(null, args)
    } else if (
        Array.isArray(callable) &&
        callable.length === 2 &&
        callable[0]
    ) {
        args.unshift(callable[0][callable[1]])

        return callbackCaller.apply(null, args)
    }
}

export function numberSummarize(num, digits) {
    const lookup = [
        {value: 1, symbol: ""},
        {value: 1e3, symbol: "k"},
        {value: 1e6, symbol: "M"},
        {value: 1e9, symbol: "G"},
        {value: 1e12, symbol: "T"},
        {value: 1e15, symbol: "P"},
        {value: 1e18, symbol: "E"}
    ];

    const pattern = /\.0+$|(\.\d*[1-9])0+$/
    const item = lookup.slice().reverse().find(item => {
        return num >= item.value
    })

    return item ? (num / item.value)
        .toFixed(digits || 0)
        .replace(pattern, "$1") + item.symbol : '0'
}

export function toSlugUrlFormat(value) {
    if (typeof value === "string") {
        return value
            .toLowerCase()
            .replace(/[^0-9a-z-\s]/gi, '')
            .replace(/\s+/gi, '-')
    }

    return value
}

export function toCodeUnderscoreFormat(value) {
    if (typeof value === "string") {
        return value
            .toLowerCase()
            .replace(/[^0-9a-z-\s]/gi, '')
            .replace(/\s+/gi, '_')
    }

    return value
}

export function toCurrencyFormat(number) {
    return new Intl.NumberFormat("id-ID", {style: "currency", currency: "IDR"}).format(number);
}

export function numberSeparatorPoint(number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
}