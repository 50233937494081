<template>
    <form @submit.prevent="onFormSubmit">
        <div class="mb-3">
            <label for="identifier" class="form-label">Nama Pengguna</label>
            <input type="text" ref="identifierInput" id="identifier" :class="{'form-control': true, 'is-invalid': eIdentifier.length}" v-model.trim="fields.identifier" placeholder="Nama Pengguna" :disabled="processed">
            <div class="invalid-feedback">
                <div v-for="invalid in eIdentifier" :key="invalid.$uid">{{ invalid.$message }}</div>
            </div>
        </div>
        <div class="mb-3">
            <label for="password" class="form-label">Kata sandi</label>
            <div :class="{'input-group': true, 'is-invalid': eMessagesPassword.length}">
                <input :type="showPassword?'text':'password'" id="password" :class="{'form-control': true, 'is-invalid': eMessagesPassword.length}" placeholder="Masukkan kata sandi" v-model.trim="fields.password" :disabled="processed">
                <button type="button" class="btn btn-outline-secondary" @click="showPassword = !showPassword">
                    <fa-icon v-if="showPassword" icon="eye-slash"/>
                    <fa-icon v-else icon="eye"/>
                </button>
            </div>
            <div class="invalid-feedback">
                <div v-for="invalid in eMessagesPassword" :key="invalid.$uid">{{ invalid.$message }}</div>
            </div>
        </div>
        <div class="mb-3 d-flex align-items-center">
            <div :class="{'flex-fill me-2': showForgotPassword}">
                <button type="submit" class="btn btn-primary">
                    <fa-icon v-if="processed" icon="spinner" pulse/><fa-icon v-else icon="sign-in"/> Masuk
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import {helpers, required} from "@vuelidate/validators"
import {httpErrorParse, isHttpErrorCode} from "@/util/httpErrorHandler";
import {isRoleGranted, ROLE_ADMIN} from "@/util/userRoles";

export default {
    name: "LoginForm",
    emits: ['success', 'failed'],
    props: {
        showForgotPassword: {type: Boolean, default: true}
    },
    setup() {
        return {v$: useVuelidate()}
    },
    data() {
        return {
            processed: false,
            fields: {
                identifier: null,
                password: null
            },
            showPassword: false
        }
    },
    validations() {
        return {
            fields: {
                identifier: {
                    required: helpers.withMessage('Nilai ini tidak boleh kosong dan harus valid.', required)
                },
                password: {
                    required: helpers.withMessage('Nilai ini tidak boleh kosong.', required)
                }
            }
        }
    },
    computed: {
        eIdentifier() {
            return this.v$.fields.identifier.$errors
        },
        eMessagesPassword() {
            return this.v$.fields.password.$errors
        },
    },
    mounted() {
        this.$nextTick(()=> this.$refs.identifierInput?.focus())
    },
    methods: {
        async onFormSubmit() {
            const isValid = await this.v$.$validate()
            if (this.processed || !isValid) {
                return
            }

            this.processed = true
            const identifier = this.fields.identifier
            const password = this.fields.password

            try {
                const response = await this.$http.create().post('/login/validate', {identifier, password})
                const payload = response.data.payload
                if (this.isValidRoles(payload.user.roles)) {
                    this.$store.dispatch('user/setUser', payload)
                        .then(()=> this.$emit('success'))
                } else {
                    this.$emit('failed', {
                        message: 'Nama pengguna atau kata sandi salah.',
                        errorCode: 'login.invalid_credentials'
                    })
                }
            } catch (err) {
                if (isHttpErrorCode('login.invalid_credentials', err)) {
                    this.$emit('failed', {
                        message: 'Nama pengguna atau kata sandi salah.',
                        errorCode: 'login.invalid_credentials'
                    })
                } else {
                    this.$emit('failed', httpErrorParse(err))
                }
            }

            this.processed = false
        },
        isValidRoles(roles) {
            return isRoleGranted(roles, [ROLE_ADMIN])
        }
    }
}
</script>

<style scoped>
</style>