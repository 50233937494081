import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faSpinner,
    faInfoCircle,
    faTimes,
    faTimesCircle,
    faPlus,
    faPencil,
    faTrash,
    faSave,
    faCheck,
    faCheckSquare,
    faCheckCircle,
    faEye,
    faEyeSlash,
    faSignIn,
    faSignOut,
    faAngleUp,
    faAngleDown,
    faArrowLeft,
    faArrowRight,
    faArrowDown,
    faHome,
    faNavicon,
    faAddressCard,
    faUser,
    faUsersCog,
    faRefresh,
    faSearch,
    faHistory,
    faCog,
    faFilter,
    faExclamationCircle,
    faPaperPlane,
    faFileAlt,
    faFileDownload,
    faBullhorn,
    faImage,
    faSquare,
    faUpload,
    faBell,
    faQuestionCircle,
    faFileLines,
    faRightLeft,
    faPhone,
    faMoneyCheckDollar,
    faLock,
    faBuildingColumns,
    faSliders,
    faBox,
    faMoneyCheckAlt,
    faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faSpinner,
    faInfoCircle,
    faTimes,
    faTimesCircle,
    faPlus,
    faPencil,
    faTrash,
    faSave,
    faCheck,
    faCheckSquare,
    faCheckCircle,
    faEye,
    faEyeSlash,
    faSignIn,
    faSignOut,
    faAngleUp,
    faAngleDown,
    faArrowLeft,
    faArrowRight,
    faArrowDown,
    faHome,
    faNavicon,
    faAddressCard,
    faUser,
    faUsersCog,
    faRefresh,
    faSearch,
    faHistory,
    faCog,
    faFilter,
    faExclamationCircle,
    faPaperPlane,
    faFileAlt,
    faFileDownload,
    faBullhorn,
    faImage,
    faSquare,
    faUpload,
    faBell,
    faQuestionCircle,
    faFileLines,
    faRightLeft,
    faPhone,
    faMoneyCheckDollar,
    faLock,
    faBuildingColumns,
    faSliders,
    faBox,
    faMoneyCheckAlt,
    faCalendarAlt
);

export default {
    install(app) {
        app.component("fa-icon", FontAwesomeIcon);
    }
}