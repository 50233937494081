const STORAGE_KEY = '_spa_usr'
const getUserStorage = ()=>{
    const user = localStorage.getItem(STORAGE_KEY)
    return user ? JSON.parse(user) : {
        acn: null,
        tkn: null
    }
}

const setUserStorage = user => {
    localStorage.setItem(STORAGE_KEY, user ? JSON.stringify(user) : '')
}

const isNotBlank = value => {
    return typeof value !== "undefined" && value !== null && value !== ''
}

export default {
    namespaced: true,
    state(){
        const user = getUserStorage()

        return {
            account: user?.acn || null,
            token: user?.tkn || null
        }
    },
    getters: {
        getAccount: state => state.account,
        getToken: state => state.token,
        isAuthorized: state => isNotBlank(state.account) && isNotBlank(state.token)
    },
    actions: {
        setUser({commit}, user) {
            commit('SET_USER', user)
        },
        setAccount({commit}, account) {
            commit('SET_ACCOUNT', account)
        },
        setToken({commit}, token) {
            commit('SET_TOKEN', token)
        },
        removeUser({commit}) {
            commit('REMOVE_USER')
        }
    },
    mutations: {
        SET_USER(state, user) {
            const acn = state.account = user?.user || null
            const tkn = state.token = user?.token || null
            setUserStorage({acn, tkn})
        },
        SET_ACCOUNT(state, account) {
            const acn = state.account = account
            const tkn = state.token
            setUserStorage({acn, tkn})
        },
        SET_TOKEN(state, token) {
            const acn = state.account
            const tkn = state.token = token
            setUserStorage({acn, tkn})
        },
        REMOVE_USER(state) {
            const acn = state.account = null
            const tkn = state.token = null
            setUserStorage({acn, tkn})
        }
    }
}