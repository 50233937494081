<template>
    <div class="login-container border rounded shadow py-3 px-4 mx-auto mt-3 mt-lg-5">
        <h1 class="fs-2 text-center fw-bolder mb-0">Login</h1>
        <div class="fw-semibold text-center text-primary mb-4"><b>SMSPay</b>Admin</div>
        <AppMessage :message="message.text" :type="message.type" class="mb-3"/>
        <LoginForm @success="onLoginSuccess" @failed="onLoginFailed"/>
    </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";
import AppMessage from "@/components/App/AppMessage";

export default {
    name: "HomeView",
    components: {LoginForm, AppMessage},
    data() {
        return {
            message: {
                text: null,
                type: null
            }
        }
    },
    computed: {
        isAuthorized() {
            return this.$store.getters['user/isAuthorized'] || false
        }
    },
    created() {
        if (this.isAuthorized) {
            this.redirectToDashboard()
        }
    },
    methods: {
        redirectToDashboard() {
            this.$router.push({name: 'HomeDashboard'})
        },
        onLoginSuccess() {
            this.redirectToDashboard()
        },
        onLoginFailed(e) {
            this.setMessage(e.message, 'danger')
        },
        setMessage(text, type) {
            this.message.text = text
            this.message.type = type || null
        }
    }
}
</script>

<style scoped>
.login-container {
    max-width: 400px;
}
</style>