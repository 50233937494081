import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {title: 'SMS Pay Admin'}
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: ()=> import('../views/Dashboard/DashboardView.vue'),
    meta: {guard: true},
    children: [
      {
        path: '',
        name: 'HomeDashboard',
        component: ()=> import('../views/Dashboard/HomeDashboardView.vue'),
        meta: {title: "Home Dashboard"}
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ()=> import('../views/Dashboard/ProfileView.vue'),
        meta: {title: "Profile"}
      },
      {
        path: 'user/registration',
        name: 'Registration',
        component: ()=> import('../views/Dashboard/RegistrationView.vue'),
        meta: {title: "Pendaftaran"}
      },
      {
        path: 'user/registration/verify/:id',
        name: 'RegistrationVerify',
        component: ()=> import('../views/Dashboard/RegistrationVerifyView.vue'),
        meta: {title: "Verifikasi Pendaftaran"}
      },
      {
        path: 'user/account/:id/history',
        name: 'AccountHistory',
        component: ()=> import('../views/Dashboard/AccountHistoryView.vue'),
        meta: {title: "Riwayat Akun"}
      },
      {
        path: 'user/account/:id/submission',
        name: 'AccountSubmission',
        component: ()=> import('../views/Dashboard/AccountSubmissionView.vue'),
        meta: {title: "Pengajuan Akun"}
      },
      {
        path: 'admin-list',
        name: 'AdminList',
        component: ()=> import('../views/Dashboard/AdminListView.vue'),
        meta: {title: "Daftar Admin"}
      },
      {
        path: 'user/list',
        name: 'UserList',
        component: ()=> import('../views/Dashboard/UserListView.vue'),
        meta: {title: "Daftar Pengguna"}
      },
      {
        path: 'user/user-subscription',
        name: 'UserSubscription',
        component: ()=> import('../views/Dashboard/UserSubscriptionView.vue'),
        meta: {title: "User Berlangganan"}
      },
      {
        path: 'account/:id',
        name: 'AccountDetail',
        component: ()=> import('../views/Dashboard/AccountDetailView.vue'),
        meta: {title: "Detail Akun"}
      },
      {
        path: 'notification/broadcast',
        name: 'BroadcastNotification',
        component: ()=> import('../views/Dashboard/BroadcastNotificationView.vue'),
        meta: {title: "Siaran Pemberitahuan"}
      },
      {
        path: 'notification/category',
        name: 'NotificationCategory',
        component: ()=> import('../views/Dashboard/NotificationCategoryView.vue'),
        meta: {title: "Kategori Pemberitahuan"}
      },
      {
        path: 'transaction/refund',
        name: 'RefundList',
        component: ()=> import('../views/Dashboard/RefundListView.vue'),
        meta: {title: "Daftar Refund"}
      },
      {
        path: 'transaction/refund/:id',
        name: 'RefundDetail',
        component: ()=> import('../views/Dashboard/RefundDetailView.vue'),
        meta: {title: "Detail Refund"}
      },
      {
        path: 'transaction/subscription-package-purchase-report',
        name: 'SubscriptionPackagePurchaseReport',
        component: ()=> import('../views/Dashboard/SubscriptionPackagePurchaseReportView.vue'),
        meta: {title: "Laporan Pembelian Paket Berlangganan"}
      },
      {
        path: 'post/new-article',
        name: 'NewArticle',
        component: ()=> import('../views/Dashboard/NewArticleView.vue'),
        meta: {title: "Artikel Baru"}
      },
      {
        path: 'post/edit-article/:id',
        name: 'EditArticle',
        component: ()=> import('../views/Dashboard/EditArticleView.vue'),
        meta: {title: "Ubah Artikel"}
      },
      {
        path: 'post/article-list',
        name: 'ArticleList',
        component: ()=> import('../views/Dashboard/ArticleListView.vue'),
        meta: {title: "Daftar Artikel"}
      },
      {
        path: 'post/category',
        name: 'PostCategory',
        component: ()=> import('../views/Dashboard/PostCategoryView.vue'),
        meta: {title: "Kategori"}
      },
      {
        path: 'post/featured-post',
        name: 'FeaturedPost',
        component: ()=> import('../views/Dashboard/FeaturedPostView.vue'),
        meta: {title: "Pos Unggulan"}
      },
      {
        path: 'prefix-operator-mapping',
        name: 'PrefixOperatorMapping',
        component: ()=> import('../views/Dashboard/PrefixOperatorMappingView.vue'),
        meta: {title: "Prefix Operator Mapping"}
      },
      {
        path: 'transaction/top-up-balance-request',
        name: 'TopUpBalanceRequest',
        component: ()=> import('../views/Dashboard/TopUpBalanceRequestView.vue'),
        meta: {title: "Permintaan Isi Saldo"}
      },
      {
        path: 'transaction/top-up-balance-request/:id',
        name: 'TopUpBalanceRequestDetail',
        component: ()=> import('../views/Dashboard/TopUpBalanceRequestDetailView.vue'),
        meta: {title: "Detail Permintaan Isi Saldo"}
      },
      {
        path: 'deposit-bank',
        name: 'DepositBank',
        component: ()=> import('../views/Dashboard/DepositBankView.vue'),
        meta: {title: "Bank Deposit"}
      },
      {
        path: 'system-config',
        name: 'SystemConfig',
        component: ()=> import('../views/Dashboard/SystemConfigView.vue'),
        meta: {title: "System Config"}
      },
      {
        path: 'subscription-package',
        name: 'SubscriptionPackage',
        component: ()=> import('../views/Dashboard/SubscriptionPackageView.vue'),
        meta: {title: "Paket Berlangganan"}
      },
      {
        path: 'smspay-standard-fees',
        name: 'SMSPayStandardFees',
        component: ()=> import('../views/Dashboard/SMSPayStandardFeesView.vue'),
        meta: {title: "Biaya Standar SMSPay"}
      }
    ]
  },
  {
    path: "/not-found",
    name: 'NotFound',
    component: ()=> import('../views/NotFoundView.vue'),
    meta: {title: "Not Found"}
  },
  { path: "/:catchAll(.*)", redirect: '/not-found' }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
